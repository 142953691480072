import { BehaviorSubject, Observable } from 'rxjs';
import { Merchant } from '../interfaces/merchant.interface';

export class MerchantsService {
  private gridDataSubject$ = new BehaviorSubject<Merchant[]>([]);
  private _gridData$ = this.gridDataSubject$.asObservable();
  private isMapOpenSubject$ = new BehaviorSubject<boolean>(false);
  private _isMapOpen$ = this.isMapOpenSubject$.asObservable();

  get isMapOpen$(): Observable<boolean> {
    return this._isMapOpen$;
  }

  get gridData$(): Observable<Merchant[]> {
    return this._gridData$;
  }

  get gridData(): Merchant[] {
    return this.gridDataSubject$.value;
  }

  set gridData(value: Merchant[]) {
    this.gridDataSubject$.next(value);
  }

  toggleMapOpen(isMapOpen?: boolean): void {
    this.isMapOpenSubject$.next(isMapOpen ?? !this.isMapOpenSubject$.value);
  }

  updateGridData(gridData: Merchant[]): void {
    this.gridDataSubject$.next(gridData);
  }

  getInfowindowContent(merchant: Merchant): string {
    let returnValue = '';
    const directions_url = 'https://www.google.com/maps/dir/?api=1' +
      '&origin=Current+Location' +
      `&destination=${merchant.lat},${merchant.lng}`;
    returnValue += `<h6>${merchant.name}</h6>`;
    if (merchant.business_type_name) {
      returnValue += '<p class="mb-1">';
      returnValue += `<span><b>${merchant.business_type_name}</b></span>`
      returnValue += '</p>'
    }
    if (merchant.address) {
      returnValue += '<p class="mb-1">';
      returnValue += `<span>${merchant.address}</span>`;
      if ((merchant.city + merchant.state + merchant.postal_code).length) {
        returnValue += `<br><span>${merchant.city}, ${merchant.state} ${merchant.postal_code}</span>`;
      }
      returnValue += '</p>';
    }
    returnValue += `<div class="mb-2"><a href="${directions_url}" target="_blank">Get Directions</a></div>`;
    if (merchant.website || merchant.phone || merchant.email) {
      returnValue += '<dl>';

      if (merchant.website) {
        returnValue += '<dt>Website</dt>';
        returnValue += `<dd><a href="https://${merchant.website}" target="_blank">${merchant.website}</a></dd>`;
      }
      if (merchant.phone) {
        returnValue += '<dt>Phone</dt>';
        returnValue += `<dd><a href="tel:${merchant.phone}">${merchant.phone}</a></dd>`;
      }
      if (merchant.email) {
        returnValue += '<dt>Email</dt>';
        returnValue += `<dd><a href="mailto:${merchant.email}">${merchant.email}</a></dd>`;
      }

      returnValue += '</dl>';
    }
    returnValue += `<div class="mb-2"><a href="/merchants/${merchant.idy}" target="_blank">View More</a></div>`;

    return returnValue;
  }
}