import { Controller } from '@hotwired/stimulus';
import { Nullable } from '../interfaces/nullable.type';
import { Merchant } from '../interfaces/merchant.interface';
import { MapService } from '../services/map.service';

// Connects to data-controller="area_search"
export default class extends Controller {
  static targets = ['map', 'error', 'search', 'searchInput', 'businessTypeInput'];
  map: google.maps.Map;
  lat: Nullable<number>;
  lng: Nullable<number>;
  name: string;
  markers: google.maps.Marker[] = [];
  hasInitializedMap = false;
  errorTarget: HTMLElement;
  mapTarget: HTMLElement;
  searchTarget: HTMLElement;
  searchInputTarget: HTMLInputElement;
  businessTypeInputTarget: HTMLInputElement;

  connect(reset = false) {
    console.log(navigator);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          console.info('Coordinates:', this.lat, this.lng);
          if (window.google != null) {
            this.initMap(reset);
          }
        },
        (error) => {
          this.errorTarget.textContent = error.message;
          this.errorTarget.classList.remove('hidden');
        });
    } else {
      this.errorTarget.textContent = 'Geolocation is not supported by this browser.';
      this.errorTarget.classList.remove('hidden');
    }
  }

  initMap(reset = false) {
    if ((this.hasInitializedMap && reset == false) || this.lat == null || this.lng == null) {
      return;
    }
    this.hasInitializedMap = true;
    // console.info('Init Map');
    this.map = new google.maps.Map(this.mapTarget, {
      center: new google.maps.LatLng(this.lat, this.lng),
      zoom: 10
    });
    this.map.addListener('dragend', () => {
      this.mapMoved();
    });
    this.loadMarkers();
  }

  clearMarkers() {
    this.markers.forEach((marker: google.maps.Marker) => {
      marker.setMap(null);
    });
    this.markers = [];
  }

  async loadMarkers(clearPoints = false) {
    // console.info('Load Markers');
    if (clearPoints) {
      this.clearMarkers();
    }
    const search = this.searchInputTarget.value;
    const businessType = this.businessTypeInputTarget.value;
    const response = await fetch(this.data.get('url') + '/?' +
      new URLSearchParams({
        latitude: !search ? this.lat?.toString() ?? '' : '',
        longitude: !search ? this.lng?.toString() ?? '' : '',
        search,
        businessType,
        radius: '10'
      }), { mode: 'same-origin', headers: { 'Accept': 'application/json' } }
    );
    if (!response.ok) {
      console.error(response);
      this.errorTarget.textContent = `Failed to load map points: ${response.status}`;
      this.errorTarget.classList.remove('hidden');
    } else {
      const data_array = await response.json();
      // console.info(data_array);
      data_array.forEach((item: Merchant) => {
        const lat = parseFloat(item.lat);
        const lng = parseFloat(item.lng);
        if (lat == null || lng == null || isNaN(lat) || isNaN(lng)) {
          return;
        }

        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(lat, lng),
          map: this.map,
          title: item.name,
        });

        let infowindow: google.maps.InfoWindow | undefined;

        google.maps.event.addListener(marker, 'click', () => {
          infowindow = infowindow ?? new google.maps.InfoWindow({
            content: window.cts.services.merchants.getInfowindowContent(item),
          });
          infowindow.open({ anchor: marker, map: this.map });
        });
        this.markers.push(marker);
      });
      if (this.markers.length) {
        this.autoFitMarkers();
      }
    }
  }

  autoFitMarkers() {
    this.map.fitBounds(MapService.getBoundsFromMarkers(this.markers, this.lat, this.lng));
  }

  markerClick(infowindow: google.maps.InfoWindow, marker: google.maps.Marker) {
    // console.info(marker);
    infowindow.open({
      anchor: marker,
      map: this.map
    });
  }

  searchArea() {
    this.lat = this.map.getCenter()?.lat();
    this.lng = this.map.getCenter()?.lng();
    // console.info(this.lat, this.lng);
    // this.searchTarget.classList.add('hidden');
    this.loadMarkers(true);
  }

  reset() {
    // this.searchTarget.classList.add('hidden');
    this.searchInputTarget.value = '';
    this.businessTypeInputTarget.value = '';
    this.connect(true);
  }

  mapMoved() {
    // var lat = this.map.getCenter().lat();
    // var lng = this.map.getCenter().lng();
    // console.info(lat,lng);
    this.searchTarget.classList.remove('hidden');
  }

}
