import { Controller } from '@hotwired/stimulus';
import { Nullable } from '../interfaces/nullable.type';
import { MapService } from '../services/map.service';
import { TrackingPoint } from '../interfaces/tracking_point.interface';

// Connects to data-controller="transaction_display"
export default class extends Controller {
  static targets = ['map', 'error', 'deliver', 'track', 'confirmBtn', 'confirmationCode', 'userLatInput', 'userLngInput'];
  map: google.maps.Map;
  userMarker: google.maps.marker.AdvancedMarkerView;
  transactionMarker: google.maps.marker.AdvancedMarkerView;
  trackingPointsMarkers: google.maps.marker.AdvancedMarkerView[];
  userLat: Nullable<number>;
  userLng: Nullable<number>;
  merchantLat: Nullable<number>;
  merchantLng: Nullable<number>;
  isDelivery: boolean;
  isTracking: boolean;
  autoAddTrackingPoint: boolean;
  trackingPoints: TrackingPoint[];
  trackingCoordinates: google.maps.LatLng[];
  errorTarget: HTMLElement;
  mapTarget: HTMLElement;
  deliverTarget: HTMLElement;
  trackTarget: HTMLElement;
  confirmBtnTarget: HTMLElement;
  userLatInputTarget: HTMLInputElement;
  userLngInputTarget: HTMLInputElement;
  confirmationCodeTarget: HTMLInputElement;
  hasInitializedMap = false;
  justTracked = false;

  connect() {
    console.log('Connect...');
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.userLat = position.coords.latitude;
          this.userLng = position.coords.longitude;
          console.info('Coordinates:', this.userLat, this.userLng);
          if (window.google != null) {
            setTimeout(() => {
              this.initMap();
            }, 2000);
          }
        },
        (error) => {
          this.errorTarget.textContent = error.message;
          this.errorTarget.classList.remove('hidden');
        });
    } else {
      this.errorTarget.textContent = 'Geolocation is not supported by this browser.';
      this.errorTarget.classList.remove('hidden');
    }
  }

  initMap(): void {
    console.log('Init Map...');
    
    this.isDelivery = this.data.get('delivery') == 'true';
    this.isTracking = this.data.get('track') == 'true';
    this.autoAddTrackingPoint = this.data.get('add_tracking_point') == '1';
    if (this.data.get('tracking_points')) {
      this.trackingPoints = JSON.parse(this.data.get('tracking_points') ?? '[]');
    } else {
      this.trackingPoints = [];
    }
    

    if (this.hasInitializedMap || ((this.userLat == null || this.userLng == null) && this.isDelivery)) {
      return;
    }
    this.hasInitializedMap = true;

    const transactionLat = parseFloat(this.data.get('lat') ?? '');
    const transactionLng = parseFloat(this.data.get('lng') ?? '');

    this.map = new google.maps.Map(this.mapTarget, {
      center: new google.maps.LatLng(this.userLat || transactionLat, this.userLng || transactionLng),
      zoom: 17,
      mapId: 'transaction_map',
    });

    if (transactionLat != null && transactionLng != null && !isNaN(transactionLat) && !isNaN(transactionLng)) {
      const packageIcon = document.createElement('div');
      packageIcon.innerHTML = '<span class="material-symbols-outlined">package</span>';
      this.transactionMarker = new google.maps.marker.AdvancedMarkerView({
        map: this.map,
        position: new google.maps.LatLng(transactionLat, transactionLng),
        content: new google.maps.marker.PinView({
          glyph: packageIcon,
          glyphColor: '#FFFFFF',
          background: '#198754',
          borderColor: '#198754',
        }).element,
      });
    }
    if (this.userLat != null && this.userLng != null && this.isDelivery) {
      this.userLatInputTarget.value = `${this.userLat}`;
      this.userLngInputTarget.value = `${this.userLng}`;
      const userIcon = document.createElement('div');
      userIcon.innerHTML = '<span class="material-symbols-outlined">person</span>';
      this.userMarker = new google.maps.marker.AdvancedMarkerView({
        map: this.map,
        position: new google.maps.LatLng(this.userLat, this.userLng),
        content: new google.maps.marker.PinView({
          glyph: userIcon,
          background: '#0d6efd',
          borderColor: '#0d6efd',
          glyphColor: '#FFFFFF'
        }).element,
      });
    }

    this.merchantLat = parseFloat(this.data.get('merchant_lat') ?? '');
    this.merchantLng = parseFloat(this.data.get('merchant_lng') ?? '');
    this.trackingCoordinates = [];
    this.trackingPointsMarkers = [];
    if (this.merchantLat != null && this.merchantLng != null && this.isTracking) {
      const merchantIcon = document.createElement('div');
      merchantIcon.innerHTML = '<span class="material-symbols-outlined">storefront</span>';
      this.trackingPointsMarkers.push(new google.maps.marker.AdvancedMarkerView({
        map: this.map,
        position: new google.maps.LatLng(this.merchantLat, this.merchantLng),
        content: new google.maps.marker.PinView({
          glyph: merchantIcon,
          background: '#0d6efd',
          borderColor: '#0d6efd',
          glyphColor: '#FFFFFF'
        }).element,
      }));
      this.trackingCoordinates.push(new google.maps.LatLng(this.merchantLat, this.merchantLng));
    }

    this.trackingPoints.forEach((trackingPoint) => {
      if (this.autoAddTrackingPoint) {
        const trackingPointDate = new Date(trackingPoint.track_date);
        const diff = Math.abs(new Date().getTime() - trackingPointDate.getTime());
        const diffMinutes = Math.ceil(diff / (1000 * 60));
        if (diffMinutes < 15) {
          this.justTracked = true;
        }
      }
      const trackingPointIcon = document.createElement('div');
      trackingPointIcon.innerHTML = '<span class="material-symbols-outlined">local_shipping</span>';
      this.trackingPointsMarkers.push(new google.maps.marker.AdvancedMarkerView({
        map: this.map,
        position: trackingPoint.location,
        title: `${(new Date(trackingPoint.track_date)).toLocaleString()}: ${trackingPoint.city}, ${trackingPoint.state}, ${trackingPoint.postalcode}`,
        content: new google.maps.marker.PinView({
          glyph: trackingPointIcon,
          glyphColor: '#FFFFFF',
          background: '#0dcaf0',
          borderColor: '#0dcaf0',
        }).element,
      }));
      this.trackingCoordinates.push(trackingPoint.location);
    });

    if (this.trackingPointsMarkers.length > 0) {
      const trackPath = new google.maps.Polyline({
        path: this.trackingCoordinates,
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
  
      trackPath.setMap(this.map);
    }
    
    if (this.autoAddTrackingPoint && !this.justTracked) {
      this.addTrackingPoint();
    }

    if (this.isDelivery) {
      if (this.transactionMarker != null && this.userMarker != null) {
        this.map.fitBounds(MapService.getBoundsFromAdvancedMarkers([this.transactionMarker, this.userMarker].concat(this.trackingPointsMarkers)));
      }
    } else {
      if (this.transactionMarker != null) {
        this.map.fitBounds(MapService.getBoundsFromAdvancedMarkers([this.transactionMarker]));
      }
    }
  }

  async validateDelivery() {
    const userLat = parseFloat(this.userLatInputTarget.value);
    const userLng = parseFloat(this.userLngInputTarget.value);
    
    if (userLat == null || userLng == null || isNaN(userLat) || isNaN(userLng)) {
      this.errorTarget.textContent = 'Delivery Error: Unable to Detect your location.';
      this.errorTarget.classList.remove('hidden');
      return;
    }

    const response = await fetch(this.data.get('validate_url') + '?' +
      new URLSearchParams({
        latitude: `${userLat}`,
        longitude: `${userLng}`
      }), { mode: 'same-origin', headers: { 'Accept': 'application/json' } }
    );
    if (!response.ok) {
      const error_result = await response.json();
      this.errorTarget.textContent = `Delivery Error: ${error_result.errors.join(', ')}`;
      this.errorTarget.classList.remove('hidden');
    } else {
      const validation_result = await response.json();
      if (validation_result.confirmation_code_sent) {
        this.confirmBtnTarget.classList.remove('hidden');
        this.confirmationCodeTarget.classList.remove('hidden');
      } else if (validation_result.transaction_marked_as_shipped) {
        location.reload();
      }
    }
  }

  async trackDelivery() {
    const userLat = parseFloat(this.userLatInputTarget.value);
    const userLng = parseFloat(this.userLngInputTarget.value);
    
    if (userLat == null || userLng == null || isNaN(userLat) || isNaN(userLng)) {
      this.errorTarget.textContent = 'Tracking Error: Unable to Detect your location.';
      this.errorTarget.classList.remove('hidden');
      return;
    }

    const response = await fetch(this.data.get('tracking_url') + '?' +
      new URLSearchParams({
        latitude: `${userLat}`,
        longitude: `${userLng}`
      }), { mode: 'same-origin', headers: { 'Accept': 'application/json' } }
    );
    if (!response.ok) {
      const error_result = await response.json();
      this.errorTarget.textContent = `Tracking Error: ${error_result.errors.join(', ')}`;
      this.errorTarget.classList.remove('hidden');
    } else {
      location.reload();
    }
  }

  addTrackingPoint() {
    this.trackTarget.classList.add('hidden');
    this.trackDelivery();
  }
  
  markAsDelivered() {
    this.deliverTarget.classList.add('hidden');
    this.validateDelivery();
  }

  resetDeliveryButton() {
    this.deliverTarget.classList.remove('hidden');
  }

  async confirmDelivery() {
    const confirmationCode = this.confirmationCodeTarget.value;
    
    if (confirmationCode == null || confirmationCode == '') {
      this.errorTarget.textContent = 'Please Enter a Confirmation Code.';
      this.errorTarget.classList.remove('hidden');
      this.confirmBtnTarget.classList.remove('hidden');
      this.confirmationCodeTarget.classList.remove('hidden');
      return;
    }

    const response = await fetch(this.data.get('confirm_url') + '?' +
      new URLSearchParams({
        code: confirmationCode
      }), { mode: 'same-origin', headers: { 'Accept': 'application/json' } }
    );
    if (!response.ok) {
      const error_result = await response.json();
      this.errorTarget.textContent = `Delivery Error: ${error_result.errors.join(', ')}`;
      this.errorTarget.classList.remove('hidden');
      this.confirmationCodeTarget.value = '';
      this.confirmBtnTarget.classList.remove('hidden');
      this.confirmationCodeTarget.classList.remove('hidden');
    } else {
      const validation_result = await response.json();
      if (validation_result.transaction_marked_as_shipped) {
        location.reload();
      }
    }
  }

  confirm() {
    this.errorTarget.classList.add('hidden');
    this.confirmBtnTarget.classList.add('hidden');
    this.confirmationCodeTarget.classList.add('hidden');
    this.confirmDelivery();
  }
}
